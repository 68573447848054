import React, { useState, useEffect } from 'react';
import Service from './Service';
import WhySection from './why.js';
import FeaturesSection from './feature.js';
import Roadmap from './Roadmap';
import Dashboard from './Dashboard.js';
import Carcousel from './carcousel.js';
import ImagePopup from './ImagePopup.js';

const Home = () => {
  // const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsImagePopupOpen(true);
  //   }, 2000); 
  //   return () => clearTimeout(timer);
  // }, []);
  // const handleImagePopupClose = () => {
  //   setIsImagePopupOpen(false);
  // };
  
  return (
    <>
      <Carcousel />
      <Dashboard />

      <FeaturesSection />
      <WhySection />
      <Service />
      {/* <Roadmap /> */}
     

      {/* <ImagePopup isOpen={isImagePopupOpen} onClose={handleImagePopupClose} /> */} 
    </>
  );
};

export default Home;
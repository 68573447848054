import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from './components/contact.js';
import Navbar from './components/Navbar.js';
import Home from './components/Home.js';
import ServiceDetails from './components/Servicedetails.js';
import DesignPackage from './components/Designpack';
import ConPack from './components/Conpack';

import OurProjects from './components/Project';
import ThreeDesign from './components/3ddesign';
import Ourwork from './components/Ourwork';
import Ongoing from './components/Ongoing';
import Estimate from './components/estimate';
import Consulting from './components/Consulting';


const App = () => {


  return (
    
    <Router>
      <Navbar/>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Conpack" element={< ConPack/>} />
          <Route path="/Designpack" element={<DesignPackage  />} />
          <Route path="/Project" element={< OurProjects/>} />
          <Route path="/3ddesign" element={< ThreeDesign/>} />
          <Route path="/Ourwork" element={< Ourwork/>} />
          <Route path="/Ongoing" element={< Ongoing/>} />

           <Route path="/course/:id" element={<ServiceDetails />} /> 
           <Route path="/estimate" element={< Estimate/>} />
           <Route path="/Consulting" element={<Consulting/>} />

           
      </Routes>
      <Contact/>
    </Router>
  
  );
};
export default App;

import React from 'react';
import './Conpack.css'; 

const packages = [          
  {
    title: 'Basic Package',
    price: 1899,
    details: [
      {
        summary: 'Design',
        items: ['Concept Design', 'Space Planning', 'Interior Design']
      },
      {
        summary: 'Project Management',
        items: ['Scheduling', 'Quality Control', 'Budget Management']
      },
      {
        summary: 'Structure',
        items: ['Foundation', 'Framing', 'Roofing']
      },
      {
        summary: 'Electrical',
        items: ['Wiring', 'Lighting', 'Switches']
      },
      {
        summary: 'Plumbing',
        items: ['Pipes Installation', 'Leak Testing', 'Faucets']
      },
      {
        summary: 'Finishing',
        items: ['Painting', 'Flooring', 'Trimming']
      }
    ]
  },
  {
    title: 'Standard Package',
    price: 2099,
    details: [
      {
        summary: 'Design',
        items: ['Concept Design', 'Space Planning', '3D Visualization']
      },
      {
        summary: 'Project Management',
        items: ['Scheduling', 'Quality Control', 'Contract Negotiation']
      },
      {
        summary: 'Structure',
        items: ['Foundation', 'Framing', 'Roofing']
      },
      {
        summary: 'Electrical',
        items: ['Wiring', 'Lighting', 'Electrical Panels']
      },
      {
        summary: 'Plumbing',
        items: ['Pipes Installation', 'Leak Testing', 'Water Filtration']
      },
      {
        summary: 'Finishing',
        items: ['Painting', 'Flooring', 'Decorative Features']
      }
    ]
  },
  {
    title: 'Premium Package',
    price: 2399,
    details: [
      {
        summary: 'Design',
        items: ['Concept Design', 'Space Planning', 'Custom Design Solutions']
      },
      {
        summary: 'Project Management',
        items: ['Scheduling', 'Quality Control', 'Client Consultation']
      },
      {
        summary: 'Structure',
        items: ['Foundation', 'Framing', 'Roofing', 'Insulation']
      },
      {
        summary: 'Electrical',
        items: ['Wiring', 'Lighting', 'Smart Home Integration']
      },
      {
        summary: 'Plumbing',
        items: ['Pipes Installation', 'Leak Testing', 'High-End Fixtures']
      },
      {
        summary: 'Finishing',
        items: ['Painting', 'Flooring', 'Luxury Finishes']
      }
    ]
  }
];

const Packages = () => {
  return (
    <section className='cp'>
    <div className="cp-container">
        <div className="cp-row">
      {packages.map((pkg, index) => (
        <div className="package" key={index}>
          <div className="package-header">
            <h3>{pkg.title}</h3>
            <p> {pkg.price} Rs <span>per sqft</span></p>
          </div>
          <div className="package-details">
            {pkg.details.map((detail, idx) => (
              <details key={idx}>
                <summary>{detail.summary}</summary>
                <ul>
                  {detail.items.map((item, id) => (
                    <li key={id}>{item}</li>
                  ))}
                </ul>
              </details>
            ))}
          </div>
        </div>
      ))}
    </div>
    </div>
    </section>
  );
};

export default Packages;

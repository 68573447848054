import React, { useState } from 'react';
import './Project.css';
import ThreeDesign from './3ddesign';
import Ourwork from './Ourwork';
import Ongoing from './Ongoing';


const Project = () => {
  const [selectedCategory, setSelectedCategory] = useState('websites');
  const renderContent = () => {
    switch (selectedCategory) {
      case 'ThreeDesign':
        return <ThreeDesign />;
      case 'Ourwork':
        return <Ourwork/>;
      case 'Ongoing':
        return <Ongoing/>;
      default:
        return <ThreeDesign />;
    }
};
  return (
    <div>
      <section className="port-web">
        <div className="port-web-container">
      <h1 className="port-web-head">Our Milestones</h1>
      <nav className="portfolio-nav">
        <button onClick={() => setSelectedCategory('ThreeDesign')} className={selectedCategory === 'ThreeDesign' ? 'active-link' : ''}>3D Designs</button>
        <button onClick={() => setSelectedCategory('Ourwork')} className={selectedCategory === 'Ourwork' ? 'active-link' : ''}>Completed Projects</button>
        <button onClick={() => setSelectedCategory('Ongoing')} className={selectedCategory === 'Ongoing' ? 'active-link' : ''}>Ongoing Works</button>
      </nav>

    <div className="portfolio-content">
        {renderContent()}
    </div>
  </div>

  
  </section>
  </div>
  );
};

export default Project;

// import React, { useState, useEffect } from 'react';
// import './Carcousel.css';
// import About2 from './images/ban1.png';
// import About3 from './images/ban2.png';
// import About1 from './images/ban3.png';
// import banner from './images/banvid1.mp4'
// const FullScreenCarousel = () => {
//   const images = [About1, About2, About3];
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const goToPrevious = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? images.length - 1 : prevIndex - 1
//     );
//   };
//   const goToNext = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === images.length - 1 ? 0 : prevIndex + 1
//     );
//   };
//   useEffect(() => {
//     const interval = setInterval(goToNext, 3000);
//     return () => clearInterval(interval);
//   }, [currentIndex]);
//   return (
//     <div className="carousel">
//       <div
//         className="carousel-inner"
//         style={{ transform: `translateX(-${currentIndex * 100}%)` }}
//       >
//         {images.map((image, index) => (
//           <div className="carousel-item" key={index}>
//             <img src={image} alt={`Slide ${index}`} />
//           </div>
//         ))}
//       </div>
//       <div className="carousel-dots">
//         {images.map((_, index) => (
//           <span
//             key={index}
//             className={`dot ${index === currentIndex ? 'active' : ''}`}
//             onClick={() => setCurrentIndex(index)}
//           ></span>
//         ))}
//       </div>
//     </div>
//   );
// };
// export default FullScreenCarousel;
import React, { useState, useEffect } from 'react';
import './Carcousel.css';
import video4 from './images/banvid1.mp4';
import video2 from './images/banvid2.mp4';
import video1 from './images/banvid3.mp4';
import video5 from './images/banvid4.mp4';
import video3 from './images/banvid.mp4';

const FullScreenCarousel = () => {
  const videos = [video1, video2, video3,video4,video5];
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === videos.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(goToNext,10000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="carousel">
      <div
        className="carousel-inner"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {videos.map((video, index) => (
          <div className="carousel-item" key={index}>
            <video src={video} autoPlay muted loop>
              Your browser does not support the video tag.
            </video>
          </div>
        ))}
      </div>
      <div className="carousel-dots">
        {videos.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default FullScreenCarousel;

import React from 'react';
import './Ourwork.css'
import og1 from './images/project-1.jpg'
import og2 from './images/project-2.jpg'
import og3 from './images/project-3.jpg'
import og4 from './images/project-4.jpg'
import og5 from './images/project-5.jpg'
import og6 from './images/project-6.jpg'

const ThreeDesign = () => {
  return (
    <div>
      <section className='ourwork'>
        <div className='ourwork-cont'>
          <div className='ourwork-row'>
            <div className='ourwork-col'>
              <div className='ourwork-card'>
                <div className='ourwork-img'>
                  <img src={og1}></img>
                </div>
              </div>
            </div>
            <div className='ourwork-col'>
              <div className='ourwork-card'>
                <div className='ourwork-img'>
                  <img src={og2}></img>
                </div>
              </div>
            </div><div className='ourwork-col'>
              <div className='ourwork-card'>
                <div className='ourwork-img'>
                  <img src={og3}></img>
                </div>
              </div>
            </div>
            <div className='ourwork-col'>
              <div className='ourwork-card'>
                <div className='ourwork-img'>
                  <img src={og4}></img>
                </div>
              </div>
            </div>
            <div className='ourwork-col'>
              <div className='ourwork-card'>
                <div className='ourwork-img'>
                  <img src={og5}></img>
                </div>
              </div>
            </div>
            <div className='ourwork-col'>
              <div className='ourwork-card'>
                <div className='ourwork-img'>
                  <img src={og6}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};



export default ThreeDesign;

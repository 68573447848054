import React, { useState } from "react";
import { Link } from 'react-router-dom';
import './contact.css';
import 'boxicons';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';
const courseIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Footer = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleLinkClick = () => {
        if (window.innerWidth <= 768) {
            setIsOpen(false);
        }

    };
    return (
        <footer className="footer">
            <div className="f-container">
                <div className='footer-cont'>
                    <div className="footer-icons">
                        <a href="https://wa.me/919597751076" className="footer-icon whatsapp-icon">
                            <FaWhatsapp />
                        </a>
                        <a href="tel:+919597751076" className="footer-icon phone-icon">
                            <FaPhone style={{ transform: 'rotate(90deg)' }} />
                        </a>
                    </div>
                </div>
                <div className="footer-container">
                    <div className="footer-row">
                        <div className="footer-col">
                            <div className="footer-box">
                                <h3 className="foo-h3">NAGERCOIL BUILDERS</h3>
                                <p>100% Satisfaction Rate </p>
                                <div className="footer-social">
    <div className="foot-icon">
        <a href="https://www.facebook.com/profile.php?id=61563730416828" target="_blank" rel="noopener noreferrer">
            <i className='bx bxl-facebook'></i>
        </a>
    </div>
    <div className="foot-icon">
        <a href="https://www.instagram.com/your-profile" target="_blank" rel="noopener noreferrer">
            <i className='bx bxl-instagram'></i>
        </a>
    </div>
    <div className="foot-icon">
        <a href="https://youtube.com/@nagercoilbuilders?si=kSUFRBlJi2ERoQWj" target="_blank" rel="noopener noreferrer">
            <i className='bx bxl-youtube'></i>
        </a>
    </div>
</div>

                            </div>
                        </div>
                        
                        <div className="footer-col">
                            
                            <div className="footer-box">
                            <h4 className="foo-h4">SERVICES </h4>

                                <p><Link to={`/course/${courseIds[0]}`} onClick={handleLinkClick}>Consulting</Link></p>
                                <p><Link to={`/course/${courseIds[1]}`} onClick={handleLinkClick}>Estimate</Link></p>
                                <p><Link to={`/course/${courseIds[2]}`} onClick={handleLinkClick}>Plan & Design</Link></p>
                                <p><Link to={`/course/${courseIds[3]}`} onClick={handleLinkClick}>construction</Link></p>
                                <p><Link to={`/course/${courseIds[4]}`} onClick={handleLinkClick}>Interior Design</Link></p>
                                <p><Link to={`/course/${courseIds[5]}`} onClick={handleLinkClick}>Carpentry</Link></p>

                            </div>
                        </div>
                        <div className="footer-col">
                            <div className="footer-box1">
                            <p><Link to={`/course/${courseIds[6]}`} onClick={handleLinkClick}>Electric Work</Link></p>
                            <p><Link to={`/course/${courseIds[7]}`} onClick={handleLinkClick}>3D-Elevation</Link></p>
                            <p><Link to={`/course/${courseIds[8]}`} onClick={handleLinkClick}> Flooring</Link></p>
                            <p><Link to={`/course/${courseIds[9]}`} onClick={handleLinkClick}>Painting Work</Link></p>
                            <p><Link to={`/course/${courseIds[10]}`} onClick={handleLinkClick}>Interior Work</Link></p>
                                <p><Link to={`/course/${courseIds[11]}`} onClick={handleLinkClick}>Renovation</Link></p>
                              
                            </div>
                        </div>
                        <div className="footer-col">
                            <h4 className="foo-h4">CONTACT INFO</h4>
                            <p className="foot-add"> 4/26A Adaikalapattinam,
                            Pavoorchatram
                            </p>
                            <p className="foot-con"><i className='bx bxs-phone'></i><a href="tel:+918110023456">+91 95977 51076</a></p>
                            <p className="foot-con"><i className='bx bxs-phone'></i><a href="tel:+919443102048">+91 94431 02048</a></p>
                            <p className="foot-con"><i className='bx bx-envelope'></i><a href="mailto:nagercoilbuilders@gmail.com">nagercoilbuilders31@gmail.com</a></p>
                            <p className="foot-con"><i className='bx bx-world'></i><a href="nagercoilbuilders.com">www.nagercoilbuilders.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

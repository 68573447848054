
  
  
import React from 'react';
import { useParams } from 'react-router-dom';

import './ServiceDetails.css';
import icon from './images/home.png'
import im1 from './images/consulting.png'
import im2 from './images/estimatework.png'
import im3 from './images/design & plan.png'
import im4 from './images/construction.png'
import im5 from './images/interior design.png'
import im6 from './images/cap.png'
import im7 from './images/electric work.png'
import im8 from './images/3d elevation.png'
import im9 from './images/laminate flooring.png'
import im10 from './images/painting work.png'
import im11 from './images/interior work.png'
import im12 from './images/renovation.png'

import sd1 from './images/sd1.png';
import sd2 from './images/sd2.png';
import sd3 from './images/sd3.png';
import sd4 from './images/sd4.png';
import sd5 from './images/sd5.png';
import sd6 from './images/sd6.png';
import sd7 from './images/sd7.png';
import sd8 from './images/sd8.png';
import sd9 from './images/sd9.png';
import sd10 from './images/sd10.png';
import sd11 from './images/sd11.png';
import sd12 from './images/sd12.png';

const OurServices = [
    {
        id: 1,
        name: "CONSULTING",
        image: im1,
        icon:icon,
        subimg:sd1,
        points: [
            "Define Your Goals",
            "Gather Inspiration",
            "Set a Budget",
            "Research Contractors",
            "Prepare questions"
        ]
    },
    {
        id: 2,
        name: "ESTIMATE",
        image: im2,
        icon:icon,
        subimg:sd2,
        points: [
            "Define the project scope",
            "Gather plans and drawings",
            "Get Multiple Estimates",
            "Soft and Hard copy of estimation cost",
            "Labour & Material Costs"
        ]
    },
    {
        id: 3,
        name: "PLAN & DESIGN",
        image: im3,
        icon:icon,
        subimg:sd3,
        points: [
            "3D Visualization Plans",
            "Smart Home Designs",
            "Complete Interior Design Using 3ds Max",
            "Style and Aesthetics"
           
        ]
    },
    {
        id: 4,
        name: "CONSTRUCTION",
        image: im4,
        icon:icon,
        subimg:sd4,
        points: [
            "Define the Project",
            "Project Timeline",
            "Safety Measures",
            "Warranties",
            "Maintenance Plan"
        ]
    },
    {
        id: 5,
        name: "INTERIOR DESIGN",
        image: im5,
        icon:icon,
        subimg:sd5,
        points: [
            "Color Theory",
            "Spatial Planning",
            "Lighting Techniques",
            "Technology Integration",
            "Art & Decor"
        ]
    },
    {
        id: 6,
        name: "CARPENTRY",
        image: im6,
        icon:icon,
        subimg:sd6,
        points: [
            "Lumber",
            "Plywood/Sheet goods",
            "Molding and Trim",
            "Cabinetry"
        ]
    },
    {
        id: 7,
        name: "ELECTRIC WORK",
        image: im7,
        icon:icon,
        subimg:sd7,
        points: [
            "Load Analysis",
            "Cables,Electrical boxes & Lighting Fixtures",
            "Outlets & Switches",
            "Grounding equipment",
            "Quality Control"
        ]
    },
    {
        id: 8,
        name: "3D-ELEVATION",
        image: im8,
        icon:icon,
        subimg:sd8,
        points: [
            "Front,Rear,Left & Right Side Elevation",
            "Color Palette",
            "Architectural details"

        ]
    },
    {
        id: 9,
        name: "FLOORING",
        image: im9,
        icon:icon,
        subimg:sd9,
        points: [
            "Hardwood Flooring",
            "Laminate Flooring",
            "Vinyl Flooring",
            "Tile Flooring",
            "Carpet Flooring"
        ]
    },
    {
        id: 10,
        name: "PAINTING WORK",
        image: im10,
        icon:icon,
        subimg:sd10,
        points: [
            "Interior Paint",
            "Exterior Paint",
            "Primer",
            "Additional Material",
            "Custom designs",
            "Long-lasting results"
        ]
    },
    {
        id: 11,
        name: "INTERIOR WORK",
        image: im11,
        icon:icon,
        subimg:sd11,
        points: [
            "Space optimization",
            "Functional layouts",
            "Custom solutions",
            "Material selection"

        ]
    },
    {
        id: 12,
        name: "RENOVATION",
        image: im12,
        subimg:sd12,
        icon:icon,
        points: [
            "Demolition and removal of existing structures",
            "Structural Modifications",
            "Structural improvements",
            "Painting and finishing work"

        ]
    }
];

const ServiceDetails = () => {
    const { id } = useParams();
    const course = OurServices.find(course => course.id === parseInt(id));
    if (!course) {
        return <div>Service not found!</div>;
    }
    return (
        <div>
                        <section className='detail-banner'></section>

            <section className='detail'>
                <div className='detail-cont'>
                <h1 className='detail-h1'>{course.name}</h1>

                    <div className='detail-row'>

                        <div className='detail-col2'>
                            <img src={course.subimg} alt={course.name} className='detail-image' />
                        </div>
                        <div className='detail-col1'>
                            <p className='detail-point-d'>{course.description}</p>
                            <ul className='detail-points'>
                                {course.points.map((point, index) => (
                                    <li key={index} className='detail-point'>
                                        <img src={course.icon} alt={course.name} className='detail-point-icon' />
                                        {point}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default ServiceDetails;

  
  
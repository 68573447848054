import React from 'react';
import './whyfeature.css';
import w1 from './images/w1.png'
import w2 from './images/w2.png'
import w3 from './images/w3.png'
import w4 from './images/w4.png'
import w5 from './images/w5.png'
import w6 from './images/w6.png'

const WhyChooseUs = () => {
    return (
        <section className="why">
            <div className="why-cont">
                <h1 className="why-heading">WHY <span className="why-spans">US</span></h1>
                <p className="why-title">Why you should choose <span className="why-span">NAGERCOIL BUILDERS</span> to build your dream home?</p>
                <p className="why-title"><span className="why-span">NAGERCOIL BUILDERS</span> is an excellent choice for anyone looking to build their dream home for several reasons</p>
                <div className="why-row">
                    <div className="why-col">
                        <div className="why-card">
                            <div className='why-img'>
                            <img src={w1}></img>
                            </div>
                            <h1 className="why-list">Experienced Team</h1>
                        </div>
                    </div>
                    <div className="why-col">
                        <div className="why-card">
                        <div className='why-img'>
                            <img src={w2}></img>
                            </div>
                            <h1 className="why-list">Quality Construction</h1>
                        </div>
                    </div>
                    <div className="why-col">
                        <div className="why-card">
                        <div className='why-img'>
                            <img src={w3}></img>
                            </div>
                            <h1 className="why-list">Customizable Designs</h1>
                        </div>
                    </div>
                    <div className="why-col">
                        <div className="why-card">
                        <div className='why-img'>
                            <img src={w4}></img>
                            </div>
                            <h1 className="why-list">Transparent Process</h1>
                        </div>
                    </div>
                    <div className="why-col">
                        <div className="why-card">
                        <div className='why-img'>
                            <img src={w5}></img>
                            </div>
                            <h1 className="why-list">Timely Completion</h1>
                        </div>
                    </div>
                    <div className="why-col">
                        <div className="why-card">
                        <div className='why-img'>
                            <img src={w6}></img>
                            </div>
                            <h1 className="why-list">In-house team of experts</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyChooseUs;

import React from 'react';
import './Designpack.css';
import dim from './images/package.jpg'

const DesignPack = () => {
    return (
        <section className="designpack">
            <div className="designpack-cont">
                <h1 className='designpack-title'>DESIGN PACKAGES</h1>
                <div className='designpack-img'>
                    <img src={dim}></img>
               </div>
            </div>
        </section>
    );
};

export default DesignPack;

// src/components/ConsultingPage.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Consulting.css'; // Add custom styles
import car1 from './images/cap.png';

const ConsultingPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <section className='consulting'>
      <div className='consulting-cont'>
        <div className="consulting-page">
          <div className="consulting-key-points">
            <h2>Consulting Key Points</h2>
            <ul>
              <li>Key Point 1</li>
              <li>Key Point 2</li>
              <li>Key Point 3</li>
              <li>Key Point 4</li>
            </ul>
          </div>
          <div className="image-carousel-360">
            <Slider {...settings}>
              <div className="carousel-item">
                <img src={car1} alt="360 View 1" />
              </div>
              <div className="carousel-item">
                <img src={car1} alt="360 View 2" />
              </div>
              <div className="carousel-item">
                <img src={car1} alt="360 View 3" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultingPage;

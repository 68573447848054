import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from './images/logo.png';
import "./Navbar.css";
import QuoteForm from "./contactForm";
import 'boxicons/css/boxicons.min.css'; 

const courseIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isQuoteFormOpen, setIsQuoteFormOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setIsDropdownOpen(false); 
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) { 
      setIsOpen(false);
    }
    closeDropdown();
  };

  const openQuoteForm = () => {
    setIsQuoteFormOpen(true);
  };

  const closeQuoteForm = () => {
    setIsQuoteFormOpen(false);
  };

  return (
    <div>
      <header className="navbar">
        <nav className="navbar-bottom">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="VIS" />
            </Link>
          </div>
          <button className="menu-toggle" onClick={toggleMenu}>
            {isOpen ? "✖" : "☰"}
          </button>
          <ul className={`nav-links ${isOpen ? "open" : ""}`}>
            <li id="menu">
              <Link to="/" onClick={handleLinkClick}>Services</Link>
            </li>
            <li id="menu">
              <Link to="/Conpack" onClick={handleLinkClick}>Construction Packages</Link>
            </li>
            <li id="menu">
              <Link to="/Designpack" onClick={handleLinkClick}>Design Packages</Link>
            </li>
            {/* <li className="dropdown" id="menu">
              <button onClick={toggleDropdown} className="dropdown-toggle">
                Our Services {isDropdownOpen ? <box-icon name='chevron-up' color='#0e0d0d' type='solid'></box-icon> : <box-icon type='solid' name='chevron-down' color='#0e0d0d'></box-icon>}
              </button>
              {isDropdownOpen && (
                <ul className="dropdown-menu" style={{ listStyleType: "none" }}>
                  <div className="dropdown-row">
                  
                 
                        <div className="dropdown-column">
                          <h1><Link to={`/course/${courseIds[0]}`} onClick={handleLinkClick}>PLAN & DESIGN</Link></h1>
                          <h1><Link to={`/course/${courseIds[1]}`} onClick={handleLinkClick}>CONSTRUCTION</Link></h1>     
                          <h1><Link to={`/course/${courseIds[2]}`} onClick={handleLinkClick}>CONSULTING</Link></h1>
                          <h1><Link to={`/course/${courseIds[9]}`} onClick={handleLinkClick}>PAINTING WORK</Link></h1>
                          
                        </div>
                        <div className="dropdown-column">
                          <h1><Link to={`/course/${courseIds[6]}`} onClick={handleLinkClick}>3D-ELEVATION</Link></h1>
                          <h1><Link to={`/course/${courseIds[11]}`} onClick={handleLinkClick}>INTERIOR DESIGN</Link></h1>
                          <h1><Link to={`/course/${courseIds[9]}`} onClick={handleLinkClick}>CARPENTRY</Link></h1>
                          <h1><Link to={`/course/${courseIds[10]}`} onClick={handleLinkClick}>ELECTRIC WORK</Link></h1>
                        </div>
                        <div className="dropdown-column">
                          <h1><Link to={`/course/${courseIds[6]}`} onClick={handleLinkClick}>RENOVATION</Link></h1>
                          <h1><Link to={`/course/${courseIds[11]}`} onClick={handleLinkClick}>LAMINATE FLOORING</Link></h1>
                          <h1><Link to={`/course/${courseIds[10]}`} onClick={handleLinkClick}>INTERIOR WORK</Link></h1>
                          <h1><Link to={`/course/${courseIds[3]}`} onClick={handleLinkClick}>ESTIMATE</Link></h1>
                        </div>
                      </div>
                    
                  
                  
                </ul>
              )}
            </li> */}
            <li id="menu">
              <Link to="/Project" onClick={handleLinkClick}>Delivered Projects</Link>
            </li>
            <li>
              <button onClick={openQuoteForm} className="quote-button">
                ENQUIRY
              </button>
            </li>
          </ul>
        </nav>
        <QuoteForm isOpen={isQuoteFormOpen} onClose={closeQuoteForm} />
      </header>
    </div>
  );
};

export default Navbar;

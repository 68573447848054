import React, { useState } from 'react';
import './estimate.css'; 
const EstimateCalculator = () => {
  const [area, setArea] = useState('');
  const [rate, setRate] = useState('');
  const [estimate, setEstimate] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (area && rate) {
      setEstimate(parseFloat(area) * parseFloat(rate));
    }
  };

  return (
    <div className="estimate-container">
      <aside className="estimate-sidebar">
        <h2>Estimate Information</h2>
        <ul>
          <li>Input the area in square feet.</li>
          <li>Enter the rate per square foot.</li>
          <li>Click "Calculate Estimate" to get the total cost.</li>
          <li>Make sure to input numerical values for accurate results.</li>
          <li>For complex estimates, consult with a professional.</li>
        </ul>
      </aside>
      <main className="estimate-main">
        <h1>Estimate Calculator</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="area">Area (in sq. ft):</label>
            <input
              type="number"
              id="area"
              value={area}
              onChange={(e) => setArea(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="rate">Rate (per sq. ft):</label>
            <input
              type="number"
              id="rate"
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              required
            />
          </div>
          <button type="submit">Calculate Estimate</button>
        </form>
        {estimate !== null && (
          <div className="estimate-result">
            <h2>Estimated Cost: {estimate.toFixed(2)} Rs</h2>
          </div>
        )}
      </main>
    </div>
  );
};

export default EstimateCalculator;

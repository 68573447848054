// Ourwork.js
import React from 'react';
import './Ourwork.css'
import ow1 from './images/ow1.png'
import ow2 from './images/ow2.jpeg'
import ow3 from './images/ow4.jpg'

const Ourwork = () => {
  return (
    <div>
      <section className='ourwork'>
        <div className='ourwork-cont'>
          <div className='ourwork-row'>
             <div className='ourwork-col'>
             <div className='ourwork-card'>
             <div className='ourwork-img'>
              <img src={ow1}></img>
              </div>
              </div>
              </div>
              <div className='ourwork-col'>
             <div className='ourwork-card'>
             <div className='ourwork-img'>
              <img src={ow2}></img>
              </div>
              </div>
              </div>
              <div className='ourwork-col'>
             <div className='ourwork-card'>
             <div className='ourwork-img'>
              <img src={ow3}></img>
              </div>
              </div>
              </div>
            </div>
          </div>
      </section>
    </div>
  );
};

export default Ourwork;

import React from 'react';
import './Dashboard.css'; // Assuming you have a separate CSS file for styling
import b1 from './images/d1.png'
import b2 from './images/d2.png'
import b3 from './images/d3.png'
import b4 from './images/d4.png'
import b5 from './images/d5.png'

const Box = () => {
    return (
        <section className="boxss">
            <div className="box-container">
                <h1 className='box-title'>Nagercoil <span className='box-span'>Builders </span></h1>
                <div className="box-row">
                    <div className="box-col">
                        <div className="offer">
                            <div className="box-img">
                                <img src={b1} alt="icon images" />
                            </div>
                            <h1 className='box-h1'>Calenderted date</h1>

                        </div>
                    </div>
                    <div className="box-col">
                        <div className="offer">
                            <div className="box-img">
                                <img src={b2} alt="icon images" />
</div>
                                <h1 className='box-h1'>Quality guaranteed</h1>
                            
                        </div>
                    </div>
                    <div className="box-col">
                        <div className="offer">
                            <div className="box-img">
                                <img src={b3} alt="icon images" />

</div>
                                <h1 className='box-h1'>Low Cost</h1>
                            
                        </div>
                    </div>
                    <div className="box-col">
                        <div className="offer">
                            <div className="box-img">
                                <img src={b4} alt="icon images" />
                                </div>

                                <h1 className='box-h1'>Top Rated</h1>
                        </div>
                    </div>
                    <div className="box-col">
                        <div className="offer">
                            <div className="box-img">
                                <img src={b5} alt="icon images" />
                                </div>

                                <h1 className='box-h1'>Budget Friendly</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Box;

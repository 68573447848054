import React from 'react';
import './style.css'; 

const Locations = () => {
    return (
        <section className="loc">
            <div className="loc-container">
                <h1 className="loc-title">Our <span className="loc-span">Locations</span></h1>
                <div className="loc-row">
                    <div className="loc-col">
                        <div className="loc-card">
                            <i className='bx bx-map loc-icon'></i>
                            <h1 className="loc-head">Nagercoil</h1>
                        </div>
                    </div>
                    <div className="loc-col">
                        <div className="loc-card">
                            <i className='bx bx-pin loc-icon'></i>
                            <h1 className="loc-head">Tenkasi</h1>
                        </div>
                    </div>
                    <div className="loc-col">
                        <div className="loc-card">
                            <i className='bx bx-location-plus loc-icon'></i>
                            <h1 className="loc-head">Tirunelveli</h1>
                        </div>
                    </div>
                </div>
                <h1 className="loc-sub-head">Leading <span className="loc-span">Architecture</span> and <span className="loc-span">Construction </span> Service</h1>

                <div className="loc-cont">

                    <div className="loc-row">
                        <div className="loc-col1">
                            <div className="loc-card1">
                                <h1 className="loc-h11">12</h1>
                                <h1 className="loc-h1">YEARS</h1>
                                <h1 className="loc-h1">EXPERIENCE</h1>
                            </div>
                        </div>

                        <div className="loc-col1">
                            <div className="loc-card2">
                                <h1 className="loc-he">HOME</h1>
                                <h1 className="loc-he">CONSTRUCTION</h1>
                                <h1 className="loc-he">EXPERTS !! </h1>
                                <div className="loc-one">
                                    <h1>One Stop Solution</h1>
                                    <p>Design + Construction + Interior</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Locations;

// Ongoing.js
import React from 'react';
import o1 from './images/ong3.jpeg'
import o2 from './images/ong2.jpg'
import o3 from './images/on3.png'

const Ongoing = () => {
  return (
    <div>
            <section className='ourwork'>
        <div className='ourwork-cont'>
          <div className='ourwork-row'>
             <div className='ourwork-col'>
             <div className='ourwork-card'>
             <div className='ourwork-img'>
              <img src={o1}></img>
              </div>
              </div>
              </div>
              <div className='ourwork-col'>
             <div className='ourwork-card'>
             <div className='ourwork-img'>
              <img src={o2}></img>
              </div>
              </div>
              </div>
              <div className='ourwork-col'>
             <div className='ourwork-card'>
             <div className='ourwork-img'>
              <img src={o3}></img>
              </div>
              </div>
              </div>
            </div>
          </div>
      </section>
    </div>
  );
};

export default Ongoing;
